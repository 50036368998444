import AulaService from '../../services/AulaService'
// import axios from 'axios';

const state = () => ({
    aulaLoading: false,
    getLoadingAulaSearch: false,
    aula: [],
    aulasSearch: [],
    aulasAssistidas: JSON.parse(localStorage.getItem('aulasAssistidas')) || [],
    keyword: ''
})
  
const mutations = {
    SET_AULA: (state, aula) => {
        state.aula = aula;
    },
    SET_AULAS_SEARCH: (state, aulas) => {
        state.aulasSearch = aulas;
    },
    SET_LOADING_AULA: (state, payload) => {
        state.aulaLoading = payload
    },
    SET_LOADING_AULA_SEARCH: (state, payload) => {
        state.getLoadingAulaSearch = payload
    },
 
    SET_AULAS_ASSISTIDAS: (state, payload) => {
        state.aulasAssistidas = payload
        localStorage.setItem('aulasAssistidas', JSON.stringify(payload));
    },
}

const actions = {
    async fetchBusca({commit}, { keyword = '', pageNumber = 1, sortBy = 'alfabetica', idCategory = null}){
        try {
            commit('SET_LOADING_AULA_SEARCH', true);
            const response = await AulaService.get(keyword, pageNumber, sortBy, idCategory);            
            commit('SET_AULAS_SEARCH', response.data.itens);
            commit('SET_LOADING_AULA_SEARCH', false);
        } catch (error) {
            console.warn(error)
            commit('SET_LOADING_AULA_SEARCH', false);
        }
    },
    async registrarVisualizacao({commit, rootState, dispatch}) {
        try{
            const aula = rootState.aulas.aula
            if(!aula || aula == []){
                commit('SET_ALERT', {
                    heading: 'error',
                    message: "Aula não encontrada"
                });
            }
            await AulaService.setAulaConcluida({AulaId: aula.id});
            dispatch('fetchSubject', {idAula: aula.id})

        }catch(error){
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
    async fetchVideosAssistidos({commit}){
        try {
            commit('SET_LOADING_AULA', true);
            const response = await AulaService.getAllAulasAssistidas();
            commit('SET_AULAS_ASSISTIDAS', response.data);
      
            commit('SET_LOADING_AULA', false);
        } catch (error) {
            commit('SET_LOADING_AULA', false);
            commit('LOG_USER_OUT');
        }
    },
}

const getters = {
    getAula: state => state.aula,
    getAulasAssistidas: state => state.aulasAssistidas,
    getAulasPesquisada: state => state.aulasSearch,
    getLoadingAula: state => state.aulaLoading,
    getLoadingAulaSearch: state => state.getLoadingAulaSearch
}

export default {
    state,
    getters,
    mutations,
    actions
};