import CertificadoService from '../../services/CertificadoService'

const state = () => ({
    loadingCertificados: false,
    certificado: null,
    certificados: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_certificado: false
})
  
const mutations = {
    SET_CERTIFICADO: (state, payload) => {
        state.certificado = payload
    },
    SET_CERTIFICADOS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.certificados = payload.itens
        }else{
            state.certificados = [...state.certificados, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_CERTIFICADOS: (state, payload) => {
        state.loadingCertificado = payload
    },
    SET_SHOW_MODAL_CERTIFICADO: (state, payload) => {
        state.show_modal_new_certificado = payload
    },
}

const actions = {
    async gerarCertificado({commit}, certificado){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            await CertificadoService.generate(certificado);
            commit('SET_LOADING_CERTIFICADOS', false)
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            commit('LOG_USER_OUT');
            commit('SET_REDIRECT', '/certificados');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCertificados({commit}, params = {}){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            const { keyword = '', pageNumber = 1, pageSize = 12, order = { name: 'ID', column: 'ID', sort: 'ASC' } } = params;
            const response = await CertificadoService.get(keyword, pageNumber, pageSize, order);
            commit('SET_CERTIFICADOS', response.data)
            commit('SET_LOADING_CERTIFICADOS', false)
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            commit('SET_REDIRECT', '/certificados');
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getCertificado: state => state.certificado,
    getCertificados: state => state.certificados,
    getLoadingCertificado: state => state.loadingCertificado,
    getShowModalNewCertificado: state => state.show_modal_new_certificado
}

export default {
    state,
    getters,
    mutations,
    actions
};