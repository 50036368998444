// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import notifications from './modules/notification';
import subjects from './modules/subject';
import aulas from './modules/aulas';
import periodos from './modules/periodos';
import category from './modules/category';
import avaliacao from './modules/avaliacao';
import password from './modules/password';
import email from './modules/email';
import banner from './modules/banner';
import address from './modules/address';
import timeline from './modules/timeline';
import certificado from './modules/certificado';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        notifications,
        subjects,
        aulas,
        periodos,
        category,
        avaliacao,
        password,
        email,
        banner,
        address,
        timeline,
        certificado
    }
})
