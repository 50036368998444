import TimelineService from '../../services/TimelineService'

const state = () => ({
    loadingTimeline: false,
    timeline: [],
})
  
const mutations = {
    SET_TIMELINE: (state, payload) => {
        state.timeline = payload
    },
    SET_LOADING_TIMELINE: (state, payload) => {
        state.loadingBanner = payload
    },
}

const actions = {
    async fetchTimeLine({commit}){
        try{
            commit('SET_LOADING_TIMELINE', true)
            const response = await TimelineService.get(1);
            commit('SET_TIMELINE', response.data)
            commit('SET_LOADING_TIMELINE', false)
        } catch (error) {
            commit('SET_LOADING_TIMELINE', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getTimeLine: state => state.timeline,
    getLoadingTimeline: state => state.loadingTimeline,
}

export default {
    state,
    getters,
    mutations,
    actions
};