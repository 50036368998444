const state = () => ({
    isMenuOpen: false,
    authenticateBeforeCheckout: true,
    hideScrollBar: false,
    alerts: [],
    currency: 'R$',
    storeName: 'UNIFESP',
    darkMode: JSON.parse(localStorage.getItem('theme'))  || false,
    showModalSearch: false,
    itensNavbar:  [
        {
            name: 'Home',
            url: '/',
            svg: '<svg class="h-7 w-7 opacity-75" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" class=""></path></svg>',
            sublinks: []
        },
        {
            name: 'Sobre',
            url: '/about',
            svg: '<svg class="h-7 w-7 opacity-75" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" /></svg>',
            sublinks: []
        },
        // {
        //     name: 'Módulos',
        //     url: '/subjects',
        //     sublinks: []
        // },
        // {
        //     name: 'Avaliações',
        //     url: '/avaliacoes',
        //     sublinks: []
        // },
        // {
        //     name: 'Cronograma',
        //     url: 'https://server-eadmedesporte.unifesp.br/api/file?fileName=Cronograma_MBA.pdf',
        //     target: '_blank',
        //     sublinks: []
        // }
        {
            name: 'Certificados',
            url: '/certificados',
            svg: '<svg class="h-7 w-7 opacity-75" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" /></svg>',
            sublinks: []
        },
        // {
        //     name: 'Programação',
        //     url: '/timeline',
        //     sublinks: []
        // },
        // {
        //     name: 'Inscrição',
        //     url: '/register',
        //     sublinks: []
        // },
    ],
})
  
const mutations = {
    TOGGLE_MENU(state, payload) {
        state.hideScrollBar = payload
        state.isMenuOpen = payload
    },
    TOGGLE_DARK_MODE(state) {
        localStorage.setItem('theme', JSON.stringify(!state.darkMode));
        state.darkMode = !state.darkMode;
    },
    SET_ALERT(state, alert){
        if(alert && alert.message != "Sessão expirada") {
            state.alerts.unshift(alert);
            setTimeout(() => {
                state.alerts.pop();
            }, 5000);
        }
    },
    SET_SHOW_MODAL_SEARCH(state, boolean){
        state.showModalSearch= boolean
    }
}

const getters = {
    getIsMenuOpen: state => state.isMenuOpen,
    getAlerts: state => state.alerts,
    getDarkMode: state => state.darkMode,
    getShowModalSearch: state => state.showModalSearch,
    getItensNavbar: state => state.itensNavbar
}

export default {
    state,
    getters,
    mutations
};